import {Overlay} from 'components/Overlay';
import {Button} from 'components/UIKit/Button';
import {useForm} from 'components/UIKit/Form/Form/hooks';
import {FormState} from 'components/UIKit/Form/Form/types';
import {createFormValidator} from 'components/UIKit/Form/Form/utils';
import {Textarea} from 'components/UIKit/Form/Textarea';
import {useModalOverlayLabelledById} from 'components/UIKit/ModalOverlay/context';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import React, {useCallback, useMemo} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {required as requiredValidator} from 'utils/validation';

import {Checkbox, FakeField, Form, FormField, Popup, PopupFooter, PopupHeader} from '../Common';
import {commonMessages} from '../constants';
import {useFeedbackHanlders} from '../hooks';
import {FeedbackDetails, FeedbackPopupProps, FeedbackView} from '../types';

const messages = defineMessages({
  header: {
    defaultMessage: 'Tell us more about the issue',
    description: '[header] Header of details screen in feedback form',
  },
  concernFieldLabel: {
    defaultMessage: 'Issue',
    description: '[label] Label of concern field in feedback form',
  },
  commentFieldPlaceholder: {
    defaultMessage:
      'Describe the issue in detail: The exact content you are reporting and the reason for your report',
    description: '[label] Placeholder of comment field in feedback form',
  },
  needResponseCheckboxLabel: {
    defaultMessage: 'I want a response',
    description: '[label] Label of need response checkbox in feedback form',
  },
});

type FeedbackCommentPopupProps = PopupViewProps & FeedbackPopupProps;
type FormValues = FeedbackDetails;

const FORM_VALIDATOR = createFormValidator<FormValues>({
  comment: requiredValidator,
});

export function FeedbackDetailsPopup({
  context,
  onBack,
  onClose,
  onFeedbackSend,
  onFeedbackStepLoad,
  stateRef,
  step,
}: FeedbackCommentPopupProps): JSX.Element {
  const intl = useIntl();
  const headerUid = useModalOverlayLabelledById();
  const feedback = useFeedbackHanlders({
    context,
    onFeedbackSend,
    onFeedbackStepLoad,
    stateRef,
  });

  // Handlers
  const handleFormChange = useCallback(
    (state: FormState<FormValues>) => {
      feedback.updateDetails(state.values);
    },
    [feedback],
  );
  const handleFormSubmit = useCallback(
    (state: FormState<FormValues>) => {
      feedback.updateDetails(state.values);
      feedback.next(FeedbackView.DETAILS, step);
    },
    [feedback, step],
  );

  // Render props
  const showCommentField = Boolean(step.appearance?.canComment);
  const showNeedResponseField = Boolean(
    step.appearance?.canAskResponse && !step.appearance.needContacts,
  );
  const commentRequired = Boolean(step.appearance?.commentRequired);
  const hasNextView = Boolean(step.appearance?.needContacts);
  const initialValues = useMemo<FormValues>(
    () => ({
      comment: '',
      needResponse: false,
      ...stateRef.current.details,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const form = useForm<FormValues>({
    initialValues,
    onChange: handleFormChange,
    onSubmit: handleFormSubmit,
    validator: commentRequired ? FORM_VALIDATOR : undefined,
  });

  return (
    <Popup>
      <PopupHeader id={headerUid} onBack={onBack} onClose={onClose}>
        <FormattedMessage {...messages.header} />
      </PopupHeader>
      <Form form={form}>
        <FakeField label={<FormattedMessage {...messages.concernFieldLabel} />}>
          {step.title}
        </FakeField>
        {showCommentField && (
          <FormField<string> name="comment">
            {({name, value, error, touched, onBlur, onChange}) => (
              <Textarea
                error={Boolean(error && touched)}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={intl.formatMessage(messages.commentFieldPlaceholder)}
                value={value}
              />
            )}
          </FormField>
        )}
        {showNeedResponseField && (
          <FormField<boolean> name="needResponse">
            {({name, value, onChange}) => (
              <Checkbox checked={value} name={name} onCheck={onChange}>
                <FormattedMessage {...messages.needResponseCheckboxLabel} />
              </Checkbox>
            )}
          </FormField>
        )}
      </Form>
      <PopupFooter description={step.description}>
        <Button
          color="primary"
          disabled={form.hasErrors}
          form={form.id}
          fullWidth
          size="large"
          tag="button"
          type="submit"
        >
          <Overlay loading={feedback.loading}>
            {hasNextView ? (
              <FormattedMessage {...commonMessages.nextButtonLabel} />
            ) : (
              <FormattedMessage {...commonMessages.sendButtonLabel} />
            )}
          </Overlay>
        </Button>
      </PopupFooter>
    </Popup>
  );
}
