import React, {useContext} from 'react';
import {voidFunction} from 'utils/function';

import {useInput} from './hooks';
import {FieldProps, FieldValue, FormApi, FormProps, FormValues} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormContext = React.createContext<FormApi<any>>({
  values: {},
  errors: {},
  touched: {},
  hasErrors: false,
  hasChanges: false,
  id: '',
  reset: voidFunction,
  setValue: voidFunction,
  setTouched: voidFunction,
  setError: voidFunction,
  submit: async () => undefined,
});

export function Field<V extends FieldValue, K extends string = string>({
  children,
  name,
}: FieldProps<V, K>): JSX.Element {
  const form = useContext(FormContext);
  const inpurProps = useInput<V, K>({form, name});

  return children(inpurProps);
}

export function Form<V extends FormValues>({children, form}: FormProps<V>): JSX.Element {
  return (
    <form id={form.id} onSubmit={form.submit}>
      <FormContext.Provider value={form}>{children}</FormContext.Provider>
    </form>
  );
}

Form.Field = Field;
