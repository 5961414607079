import {FeedbackPopup, FeedbackPopupProps} from 'components/FeedbackPopup';
import {PopupView, PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {useDispatchActionMap} from 'hooks/useDispatch';
import React, {FC, useMemo} from 'react';
import {loadFeedbackStep, sendFeedback} from 'store/modules/feedback';

type Props = Omit<FeedbackPopupProps, 'onFeedbackSend' | 'onFeedbackStepLoad'>;

const actionCreators = {
  loadFeedbackStep,
  sendFeedback,
};

export const Feedback: FC<Props & PopupViewProps> = (props) => {
  const actions = useDispatchActionMap(actionCreators);

  return (
    <FeedbackPopup
      {...props}
      onFeedbackSend={actions.sendFeedback}
      onFeedbackStepLoad={actions.loadFeedbackStep}
    />
  );
};

export function createFeedbackPopupView(props: Props): PopupView {
  return (popupViewProps: PopupViewProps) => <Feedback {...popupViewProps} {...props} />;
}

export function useFeedbackPopupView({context}: Props): PopupView {
  return useMemo(() => createFeedbackPopupView({context}), [context]);
}
