import {Overlay} from 'components/Overlay';
import {Button} from 'components/UIKit/Button';
import {useForm} from 'components/UIKit/Form/Form/hooks';
import {FormState} from 'components/UIKit/Form/Form/types';
import {createFormValidator} from 'components/UIKit/Form/Form/utils';
import {Input} from 'components/UIKit/Form/Input';
import {useModalOverlayLabelledById} from 'components/UIKit/ModalOverlay/context';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import React, {useCallback, useMemo} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {FeedbackContacts} from 'types/Feedback';
import {
  email as emailValidator,
  join as joinValidators,
  required as requiredValidator,
  requiredCheckbox as requiredCheckboxValidator,
} from 'utils/validation';

import {Checkbox, Form, FormField, Popup, PopupFooter, PopupHeader} from '../Common';
import {commonMessages} from '../constants';
import {useFeedbackHanlders} from '../hooks';
import {FeedbackPopupProps, FeedbackView} from '../types';

const messages = defineMessages({
  header: {
    defaultMessage: 'How can we contact you?',
    description: '[header] Header of contacts screen in feedback form',
  },
  fullNameFieldLabel: {
    defaultMessage: 'Full name',
    description: '[label] Label of full name field in feedback form',
  },
  emailFieldLabel: {
    defaultMessage: 'Email address',
    description: '[label] Label of email address field in feedback form',
  },
  confirmCheckboxLabel: {
    defaultMessage:
      'I confirm I am acting in good faith, and my report is (as) accurate and complete (as possible)',
    description: '[label] Label of confirmation checkbox in feedback form',
  },
});

type FeedbackContactsPopupProps = PopupViewProps & FeedbackPopupProps;
type FormValues = FeedbackContacts & {
  confirm: boolean;
};

const FORM_VALIDATOR = createFormValidator<FormValues>({
  fullName: requiredValidator,
  email: joinValidators([requiredValidator, emailValidator]),
  confirm: requiredCheckboxValidator,
});

export function FeedbackContactsPopup({
  context,
  onBack,
  onClose,
  onFeedbackSend,
  onFeedbackStepLoad,
  stateRef,
  step,
}: FeedbackContactsPopupProps): JSX.Element {
  const headerUid = useModalOverlayLabelledById();
  const feedback = useFeedbackHanlders({
    context,
    onFeedbackSend,
    onFeedbackStepLoad,
    stateRef,
  });

  // Handlers
  const handleFormChange = useCallback(
    (state: FormState<FormValues>) => {
      const {fullName, email} = state.values;

      feedback.updateContacts({fullName, email});
    },
    [feedback],
  );
  const handleFormSubmit = useCallback(
    (state: FormState<FormValues>) => {
      const {fullName, email} = state.values;

      feedback.updateContacts({fullName, email});
      feedback.next(FeedbackView.CONTACTS, step);
    },
    [feedback, step],
  );

  // Render props
  const initialValues = useMemo<FormValues>(
    () => ({
      fullName: '',
      email: '',
      confirm: false,
      ...stateRef.current.contacts,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const form = useForm<FormValues>({
    initialValues,
    onChange: handleFormChange,
    onSubmit: handleFormSubmit,
    validator: FORM_VALIDATOR,
  });

  return (
    <Popup>
      <PopupHeader id={headerUid} onBack={onBack} onClose={onClose}>
        <FormattedMessage {...messages.header} />
      </PopupHeader>
      <Form form={form}>
        <FormField<string> name="fullName">
          {({name, value, error, touched, onBlur, onChange}) => (
            <Input
              error={Boolean(error && touched)}
              label={<FormattedMessage {...messages.fullNameFieldLabel} />}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        </FormField>
        <FormField<string> name="email">
          {({name, value, error, touched, onBlur, onChange}) => (
            <Input
              error={Boolean(error && touched)}
              label={<FormattedMessage {...messages.emailFieldLabel} />}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        </FormField>
        <FormField<boolean> name="confirm">
          {({name, value, onChange}) => (
            <Checkbox checked={value} name={name} onCheck={onChange}>
              <FormattedMessage {...messages.confirmCheckboxLabel} />
            </Checkbox>
          )}
        </FormField>
      </Form>
      <PopupFooter description={step.appearance?.contactsNotice ?? step.description}>
        <Overlay loading={feedback.loading}>
          <Button
            color="primary"
            disabled={form.hasErrors}
            form={form.id}
            fullWidth
            size="large"
            tag="button"
            type="submit"
          >
            <FormattedMessage {...commonMessages.sendButtonLabel} />
          </Button>
        </Overlay>
      </PopupFooter>
    </Popup>
  );
}
