import cn from 'classnames';
import BaseCheckbox from 'components/Checkbox';
import {Html} from 'components/HyperText/Html';
import {Form as BaseForm} from 'components/UIKit/Form/Form';
import {FieldValue, FormValues} from 'components/UIKit/Form/Form/types';
import {Popup as BasePopup} from 'components/UIKit/Popup';
import {Footer as BasePopupFooter} from 'components/UIKit/Popup/Footer';
import {Header as BasePopupHeader} from 'components/UIKit/Popup/Header';
import React from 'react';

import styles from './index.scss';

// Popup components

type PopupProps = React.ComponentProps<typeof BasePopup>;

export const FEEDBACK_POPUP_WIDTH = '530px';

export function Popup(props: PopupProps): JSX.Element {
  return <BasePopup {...props} testId="FeedbackPopup" width={FEEDBACK_POPUP_WIDTH} />;
}

type PopupHeaderProps = React.ComponentProps<typeof BasePopupHeader>;

export function PopupHeader({children, ...props}: PopupHeaderProps): JSX.Element {
  return (
    <BasePopupHeader {...props}>
      <span className={styles.popupHeader}>{children}</span>
    </BasePopupHeader>
  );
}

type PopupFooterProps = React.PropsWithChildren<{
  description?: string;
}>;

export function PopupFooter({children, description}: PopupFooterProps): JSX.Element {
  return (
    <BasePopupFooter>
      {children}
      {description && (
        <p className={styles.popupFooterDescription}>
          <Html html={description} />
        </p>
      )}
    </BasePopupFooter>
  );
}

// Form components

type FormProps<V extends FormValues> = React.ComponentProps<typeof BaseForm<V>>;

export function Form<V extends FormValues>(props: FormProps<V>): JSX.Element {
  return (
    <div className={styles.form}>
      <BaseForm<V> {...props} />
    </div>
  );
}

type FormFieldProps<V extends FieldValue> = React.ComponentProps<typeof BaseForm.Field<V>>;

export function FormField<V extends FieldValue>(props: FormFieldProps<V>): JSX.Element {
  return (
    <div className={styles.formField}>
      <BaseForm.Field<V> {...props} />
    </div>
  );
}

type FakeFieldProps = React.PropsWithChildren<{
  label: React.ReactNode;
}>;

export function FakeField({children, label}: FakeFieldProps): JSX.Element {
  return (
    <div className={cn(styles.formField, styles.fakeField)}>
      <div className={styles.fakeFieldLabel}>{label}</div>
      <div className={styles.fakeFieldContent}>{children}</div>
    </div>
  );
}

type CheckboxProps = React.ComponentProps<typeof BaseCheckbox>;

export function Checkbox(props: CheckboxProps): JSX.Element {
  return <BaseCheckbox block className={styles.checkbox} theme="darkblue" {...props} />;
}
