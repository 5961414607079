import ClientApi from '@joomcode/joom-event-types';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {
  close as sendCloseAppEvent,
  feedbackSent as sendFeedbackSentAppEvent,
} from 'helpers/mobileAppApi';
import {useAnalytics} from 'hooks/useAnalytics';
import {useBoolean} from 'hooks/useBoolean';
import {useMountedRef} from 'hooks/useMounted';
import {usePopupManager} from 'hooks/usePopupManager';
import React, {useCallback, useMemo, useRef} from 'react';
import {useLocation} from 'react-router';
import {
  FeedbackContacts,
  FeedbackContext,
  FeedbackContextType,
  FeedbackLoadRequest,
  FeedbackSendRequest,
  FeedbackStep,
  isFeedbackSocialPostContext,
} from 'types/Feedback';
import {SocialReportReason} from 'types/SocialReportReason';
import {ifRefTrue} from 'utils/function';
import {guid} from 'utils/guid';
import {getQueryData} from 'utils/url';

import {FeedbackContactsPopup} from './FeedbackContactsPopup';
import {FeedbackDetailsPopup} from './FeedbackDetailsPopup';
import {FeedbackErrorPopup} from './FeedbackErrorPopup';
import {FeedbackReasonsPopup} from './FeedbackReasonsPopup';
import {FeedbackSuccessPopup} from './FeedbackSuccessPopup';
import {FeedbackDetails, FeedbackPopupProps, FeedbackState, FeedbackView} from './types';

type SocialPostSource = ClientApi.AnalyticsEventSocialPostOpen['payload']['source'];
type UseSocialPostSourceRenderProps = SocialPostSource;

export const useSocialPostSource = (): UseSocialPostSourceRenderProps => {
  const location = useLocation();

  return useMemo(() => getQueryData(location.search).source as SocialPostSource, [location.search]);
};

// Use Feedback Popup

type UseFeedbackPopupProps = Omit<FeedbackPopupProps, 'step'>;
type UseFeedbackPopupRenderProps = (
  view: FeedbackView,
  step: FeedbackStep,
  permanently?: boolean,
) => void;

const getFeedbackPopupComponentByView = (
  view: FeedbackView,
): React.ComponentType<PopupViewProps & FeedbackPopupProps> => {
  switch (view) {
    case FeedbackView.REASONS:
      return FeedbackReasonsPopup;

    case FeedbackView.DETAILS:
      return FeedbackDetailsPopup;

    case FeedbackView.CONTACTS:
      return FeedbackContactsPopup;

    case FeedbackView.SUCCESS:
      return FeedbackSuccessPopup;

    default:
      return FeedbackErrorPopup;
  }
};

export const useFeedbackPopup = ({
  context,
  onFeedbackStepLoad,
  onFeedbackSend,
  stateRef,
}: UseFeedbackPopupProps): UseFeedbackPopupRenderProps => {
  const popupManager = usePopupManager();

  return useCallback(
    (view: FeedbackView, step: FeedbackStep, permanently = false) => {
      const FeedbackPopupComponent = getFeedbackPopupComponentByView(view);

      popupManager.open((popupViewProps) => {
        return (
          <FeedbackPopupComponent
            {...popupViewProps}
            context={context}
            onBack={permanently ? undefined : popupViewProps.onBack}
            // eslint-disable-next-line react/jsx-no-bind
            onClose={() => {
              sendCloseAppEvent();
              popupViewProps.onClose();
            }}
            onFeedbackStepLoad={onFeedbackStepLoad}
            onFeedbackSend={onFeedbackSend}
            stateRef={stateRef}
            step={step}
          />
        );
      });
    },
    [context, onFeedbackStepLoad, onFeedbackSend, popupManager, stateRef],
  );
};

// Use Feedback State

type UseFeedbackStateRenderProps = React.MutableRefObject<FeedbackState>;

export const useFeedbackState = (): UseFeedbackStateRenderProps => {
  return useRef<FeedbackState>({reasons: {}});
};

// Use Feedback Hanlders

type UseFeedbackHanldersProps = Omit<FeedbackPopupProps, 'step'>;
type UseFeedbackHanldersRenderProps = {
  loading: boolean;
  next: (currentView: FeedbackView, nextStep?: FeedbackStep) => Promise<void>;
  updateReason: (currentStepId: string, nextStepId: string) => void;
  updateDetails: (details: FeedbackDetails) => void;
  updateContacts: (contacts: FeedbackContacts) => void;
};

const FIRST_STEP_STUB: FeedbackStep = {id: '', title: ''};

const getFeedbackLoadRequest = (
  context: FeedbackContext,
  step: FeedbackStep,
): FeedbackLoadRequest => {
  const request: FeedbackLoadRequest = {context};

  if (step !== FIRST_STEP_STUB) {
    request.id = step.id;
  }

  return request;
};

const getFeedbackSendRequest = (
  context: FeedbackContext,
  step: FeedbackStep,
  state: FeedbackState,
): FeedbackSendRequest => {
  const {appearance} = step;
  const request: FeedbackSendRequest = {
    context,
    stepId: step.id,
    clientUniqueId: guid(),
  };

  if (appearance?.canComment) {
    request.text = state.details?.comment ?? '';
  }

  if (appearance?.needContacts) {
    request.contacts = state.contacts;
  }

  if (appearance?.needContacts || appearance?.canAskResponse) {
    request.needResponse = Boolean(appearance?.needContacts || state.details?.needResponse);
  }

  return request;
};

export const useFeedbackHanlders = (
  props: UseFeedbackHanldersProps,
): UseFeedbackHanldersRenderProps => {
  const analytics = useAnalytics();
  const mountedRef = useMountedRef();
  const openPopup = useFeedbackPopup(props);
  const source = useSocialPostSource();
  const {value: loading, toggle: toggleLoading} = useBoolean(false);
  const {context, onFeedbackSend, onFeedbackStepLoad, stateRef} = props;

  let handleNext = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_v: FeedbackView, _s?: FeedbackStep, _p?: boolean) => Promise.resolve(),
    [],
  );

  // Request handlers
  const handleLoadFeedbackStep = useCallback(
    async (step: FeedbackStep): Promise<void> => {
      const request = getFeedbackLoadRequest(context, step);

      toggleLoading();
      return onFeedbackStepLoad(request)
        .then((nextStep) => {
          return handleNext(FeedbackView.REASONS, nextStep, step === FIRST_STEP_STUB);
        })
        .catch(() => openPopup(FeedbackView.ERROR, step))
        .finally(ifRefTrue(mountedRef, toggleLoading));
    },
    [context, handleNext, mountedRef, onFeedbackStepLoad, openPopup, toggleLoading],
  );
  const handleSendFeedback = useCallback(
    async (step: FeedbackStep): Promise<void> => {
      const request = getFeedbackSendRequest(context, step, stateRef.current);

      if (isFeedbackSocialPostContext(context) && step.appearance?.socialReportReason) {
        analytics.sendEvent({
          type: 'socialPostComplaintReasonClick',
          payload: {
            postId: context[FeedbackContextType.SOCIAL_POST].id,
            reason: step.appearance?.socialReportReason as SocialReportReason,
            source,
          },
        });
      }

      toggleLoading();
      return onFeedbackSend(request)
        .then((result) => {
          stateRef.current.result = result;
          sendFeedbackSentAppEvent();
          return openPopup(FeedbackView.SUCCESS, step, true);
        })
        .catch(() => openPopup(FeedbackView.ERROR, step))
        .finally(ifRefTrue(mountedRef, toggleLoading));
    },
    [analytics, context, mountedRef, onFeedbackSend, openPopup, source, stateRef, toggleLoading],
  );

  // State update handlers
  const handleUpdateReason = useCallback(
    (currentStepId: string, nextStepId: string) => {
      stateRef.current.reasons[currentStepId] = nextStepId;
    },
    [stateRef],
  );
  const handleUpdateDetails = useCallback(
    (details: FeedbackDetails) => {
      stateRef.current.details = details;
    },
    [stateRef],
  );
  const handleUpdateContacts = useCallback(
    (contacts: FeedbackContacts) => {
      stateRef.current.contacts = contacts;
    },
    [stateRef],
  );

  // Popups handler
  handleNext = useCallback(
    async (
      currentView: FeedbackView,
      nextStep?: FeedbackStep,
      permanently = false,
    ): Promise<void> => {
      if (!nextStep) {
        return handleLoadFeedbackStep(FIRST_STEP_STUB);
      }

      const {appearance, children} = nextStep;

      if (!appearance?.isLast && !children) {
        return handleLoadFeedbackStep(nextStep);
      }

      switch (currentView) {
        case FeedbackView.REASONS: {
          if (appearance?.canComment || (appearance?.canAskResponse && !appearance.needContacts)) {
            return openPopup(FeedbackView.DETAILS, nextStep, permanently);
          }

          if (appearance?.needContacts) {
            return openPopup(FeedbackView.CONTACTS, nextStep, permanently);
          }

          if (!appearance?.isLast) {
            return openPopup(FeedbackView.REASONS, nextStep, permanently);
          }

          return handleSendFeedback(nextStep);
        }

        case FeedbackView.DETAILS: {
          if (appearance?.needContacts) {
            return openPopup(FeedbackView.CONTACTS, nextStep, permanently);
          }

          return handleSendFeedback(nextStep);
        }

        case FeedbackView.CONTACTS: {
          return handleSendFeedback(nextStep);
        }

        default:
          return openPopup(FeedbackView.ERROR, nextStep);
      }
    },
    [handleLoadFeedbackStep, handleSendFeedback, openPopup],
  );

  return useMemo(
    () => ({
      loading,
      next: handleNext,
      updateReason: handleUpdateReason,
      updateDetails: handleUpdateDetails,
      updateContacts: handleUpdateContacts,
    }),
    [handleNext, handleUpdateContacts, handleUpdateDetails, handleUpdateReason, loading],
  );
};
