import {createRequestAction} from 'store/utils/requestActions';
import {
  FeedbackLoadRequest,
  FeedbackLoadResult,
  FeedbackSendRequest,
  FeedbackSendResult,
} from 'types/Feedback';

export const load = createRequestAction(
  '@feedback/LOAD_REQUEST',
  '@feedback/LOAD_SUCCESS',
  '@feedback/LOAD_FAILURE',
)<FeedbackLoadRequest, FeedbackLoadResult>();

export const send = createRequestAction(
  '@feedback/SEND_REQUEST',
  '@feedback/SEND_SUCCESS',
  '@feedback/SEND_FAILURE',
)<FeedbackSendRequest, FeedbackSendResult>();
