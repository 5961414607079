import {ErrorIllustration} from 'components/ErrorIllustration';
import {Button} from 'components/UIKit/Button';
import {useModalOverlayLabelledById} from 'components/UIKit/ModalOverlay/context';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {Scope} from 'config';
import {useScope} from 'hooks/useScope';
import {useSupportEmail} from 'hooks/useSupportEmail';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import {Popup, PopupFooter, PopupHeader} from '../Common';
import {FeedbackPopupProps} from '../types';
import styles from './index.scss';

const messages = defineMessages({
  header: {
    defaultMessage: 'Oops! Something went wrong',
    description: '[header] Header of error screen in feedback form',
  },
  description: {
    defaultMessage: 'Please try again or contact our customer support: {email}',
    description: 'Description of error screen in feedback form',
  },
  retryButtonLabel: {
    defaultMessage: 'Try again',
    description: '[label] Button to retry sending request or loading data in feedback form',
  },
});

type FeedbackErrorPopupProps = PopupViewProps & FeedbackPopupProps;

export function FeedbackErrorPopup({onClose, onBack}: FeedbackErrorPopupProps): JSX.Element {
  const scope = useScope();
  const headerUid = useModalOverlayLabelledById();

  // Render props
  const showIllustration = scope.is(Scope.GLOBAL);
  const supportEmail = useSupportEmail();

  return (
    <Popup>
      <PopupHeader onClose={onClose} />
      {showIllustration && (
        <div className={styles.imageContainer}>
          <ErrorIllustration source="feedbackPopup" />
        </div>
      )}
      <h3 className={styles.header} id={headerUid}>
        <FormattedMessage {...messages.header} />
      </h3>
      <p className={styles.description}>
        <FormattedMessage
          {...messages.description}
          values={{
            email: (
              <a className={styles.descriptionLink} href={`mailto:${supportEmail}`} key="email">
                {supportEmail}
              </a>
            ),
          }}
        />
      </p>
      <PopupFooter>
        <Button color="gray" fullWidth onClick={onBack} size="large" tag="button">
          <FormattedMessage {...messages.retryButtonLabel} />
        </Button>
      </PopupFooter>
    </Popup>
  );
}
