import {Preloader, Size as PreloaderSize} from 'components/Preloader';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {useAnalytics} from 'hooks/useAnalytics';
import {useEffectOnce} from 'hooks/useEffectOnce';
import React from 'react';
import {FeedbackContextType, isFeedbackSocialPostContext} from 'types/Feedback';

import {Popup, PopupHeader} from './Common';
import {useFeedbackHanlders, useFeedbackState, useSocialPostSource} from './hooks';
import styles from './index.scss';
import {FeedbackPopupProps as BaseFeedbackPopupProps, FeedbackView} from './types';

export type FeedbackPopupProps = Omit<BaseFeedbackPopupProps, 'stateRef' | 'step'>;

export function FeedbackPopup({
  context,
  onClose,
  onFeedbackSend,
  onFeedbackStepLoad,
}: PopupViewProps & FeedbackPopupProps): JSX.Element {
  const source = useSocialPostSource();
  const analytics = useAnalytics();
  const stateRef = useFeedbackState();
  const feedback = useFeedbackHanlders({
    context,
    onFeedbackSend,
    onFeedbackStepLoad,
    stateRef,
  });

  useEffectOnce(() => {
    if (isFeedbackSocialPostContext(context)) {
      analytics.sendEvent({
        type: 'socialPostComplaintClick',
        payload: {
          postId: context[FeedbackContextType.SOCIAL_POST].id,
          source,
        },
      });
    }

    feedback.next(FeedbackView.REASONS);
  });

  return (
    <Popup>
      <PopupHeader onClose={onClose} />
      <div className={styles.preloader}>
        <Preloader size={PreloaderSize.SMALL} />
      </div>
    </Popup>
  );
}
