import {Image} from 'components/Image';
import {Button} from 'components/UIKit/Button';
import {Icon} from 'components/UIKit/Icon';
import {useModalOverlayLabelledById} from 'components/UIKit/ModalOverlay/context';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {Scope} from 'config';
import {useScope} from 'hooks/useScope';
import React, {useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import {Popup, PopupFooter, PopupHeader} from '../Common';
import {FeedbackPopupProps} from '../types';
import styles from './index.scss';

const messages = defineMessages({
  continueShoppingButtonLabel: {
    defaultMessage: 'Continue shopping',
    description: '[label] Button to close feedback form',
  },
});

type FeedbackSuccessPopupProps = PopupViewProps & FeedbackPopupProps;

const SUCCESS_IMAGE_PX_FIT = 160; /* px */
const SUCCESS_IMAGE_VW_FIT = 160; /* px */

export function FeedbackSuccessPopup({
  onClose,
  stateRef,
}: FeedbackSuccessPopupProps): JSX.Element | null {
  const scope = useScope();
  const headerUid = useModalOverlayLabelledById();

  const [imageBroken, setImageBroken] = useState(false);
  const {result} = stateRef.current;

  if (!result) {
    onClose();
    return null;
  }

  // Render props
  const showBackendImage = scope.is(Scope.GLOBAL) && Boolean(result.image && !imageBroken);
  const showDefaultImage = scope.is(Scope.GLOBAL) && !showBackendImage;

  return (
    <Popup>
      <PopupHeader onClose={onClose} />
      {showBackendImage && (
        <div className={styles.imageContainer}>
          <Image
            className={styles.successImage}
            onComplete={setImageBroken}
            image={result.image}
            pxFit={SUCCESS_IMAGE_PX_FIT}
            vwFit={SUCCESS_IMAGE_VW_FIT}
          />
        </div>
      )}
      {showDefaultImage && (
        <div className={styles.defaultImageContainer}>
          <Icon type="illustration" name="3d-zoom" />
        </div>
      )}
      <h3 className={styles.header} id={headerUid}>
        {result.text}
      </h3>
      {result.description && <p className={styles.description}>{result.description}</p>}
      <PopupFooter>
        <Button color="gray" fullWidth onClick={onClose} size="large" tag="button">
          <FormattedMessage {...messages.continueShoppingButtonLabel} />
        </Button>
      </PopupFooter>
    </Popup>
  );
}
