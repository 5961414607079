import {
  FeedbackContacts,
  FeedbackContext,
  FeedbackLoadRequest,
  FeedbackSendRequest,
  FeedbackSendResult,
  FeedbackStep,
} from 'types/Feedback';

export enum FeedbackView {
  REASONS,
  DETAILS,
  CONTACTS,
  SUCCESS,
  ERROR,
}

export type FeedbackDetails = {
  comment?: string;
  needResponse?: boolean;
};

export type FeedbackState = {
  reasons: Record<string, string>;
  details?: FeedbackDetails;
  contacts?: FeedbackContacts;
  result?: FeedbackSendResult;
};

export type FeedbackPopupProps = {
  context: FeedbackContext;
  onFeedbackSend: (args: FeedbackSendRequest) => Promise<FeedbackSendResult>;
  onFeedbackStepLoad: (args: FeedbackLoadRequest) => Promise<FeedbackStep>;
  stateRef: React.MutableRefObject<FeedbackState>;
  step: FeedbackStep;
};
