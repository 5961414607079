import {defineMessages} from 'react-intl';

export const commonMessages = defineMessages({
  nextButtonLabel: {
    defaultMessage: 'Continue',
    description: '[label] Button to open next screen in feedback form',
  },
  sendButtonLabel: {
    defaultMessage: 'Send',
    description: '[label] Button to send feedback form',
  },
});
