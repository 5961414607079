import {Overlay} from 'components/Overlay';
import {Radio} from 'components/Radio';
import {Button} from 'components/UIKit/Button';
import {useModalOverlayLabelledById} from 'components/UIKit/ModalOverlay/context';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {FeedbackStep} from 'types/Feedback';

import {Popup, PopupFooter, PopupHeader} from '../Common';
import {commonMessages} from '../constants';
import {useFeedbackHanlders} from '../hooks';
import {FeedbackPopupProps, FeedbackView} from '../types';
import styles from './index.scss';

const messages = defineMessages({
  header: {
    defaultMessage: 'Report an issue',
    description: '[header] Header of reasons screen in feedback form',
  },
});

type FeedbackReasonsPopupProps = PopupViewProps & FeedbackPopupProps;

const getHasNextView = (step?: FeedbackStep): boolean => {
  return Boolean(
    !step ||
      !step.appearance?.isLast ||
      step.appearance?.canComment ||
      step.appearance?.canAskResponse ||
      step.appearance?.needContacts,
  );
};

const findStepById = (steps: FeedbackStep[], stepId?: string): FeedbackStep | undefined => {
  return steps.find((step) => step.id === stepId);
};

export function FeedbackReasonsPopup({
  context,
  onBack,
  onClose,
  onFeedbackSend,
  onFeedbackStepLoad,
  stateRef,
  step,
}: FeedbackReasonsPopupProps): JSX.Element {
  const headerUid = useModalOverlayLabelledById();
  const feedback = useFeedbackHanlders({
    context,
    onFeedbackSend,
    onFeedbackStepLoad,
    stateRef,
  });

  const [nextStep, setNextStep] = useState<FeedbackStep>();
  const childSteps = useMemo(() => step.children ?? [], [step.children]);

  // Handlers
  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedStepId = event.target.value;

      setNextStep(findStepById(childSteps, selectedStepId));
      feedback.updateReason(step.id, selectedStepId);
    },
    [childSteps, feedback, step.id],
  );
  const handleSubmit = useCallback(() => {
    feedback.next(FeedbackView.REASONS, nextStep);
  }, [feedback, nextStep]);

  useEffect(() => {
    const selectedStepId = stateRef.current.reasons[step.id];

    setNextStep(findStepById(childSteps, selectedStepId));
  }, [childSteps, stateRef, step.id]);

  // Render props
  const hasNextView = getHasNextView(nextStep);

  return (
    <Popup>
      <PopupHeader id={headerUid} onBack={onBack} onClose={onClose}>
        {step.title || <FormattedMessage {...messages.header} />}
      </PopupHeader>
      <ul className={styles.reasons}>
        {childSteps.map((childStep) => (
          <li className={styles.reasonsItem} key={childStep.id}>
            <Radio
              checked={childStep === nextStep}
              name={childStep.parentId || 'root'}
              value={childStep.id}
              onChange={handleSelect}
            >
              {childStep.title}
            </Radio>
          </li>
        ))}
      </ul>
      <PopupFooter description={step.description}>
        <Button
          color="primary"
          disabled={!nextStep}
          fullWidth
          onClick={handleSubmit}
          size="large"
          tag="button"
        >
          <Overlay loading={feedback.loading}>
            {hasNextView ? (
              <FormattedMessage {...commonMessages.nextButtonLabel} />
            ) : (
              <FormattedMessage {...commonMessages.sendButtonLabel} />
            )}
          </Overlay>
        </Button>
      </PopupFooter>
    </Popup>
  );
}
