import React, {useCallback, useEffect, useId, useMemo, useState} from 'react';

import {Field, Form} from '.';
import {
  ChangeEvent,
  FieldError,
  FieldProps,
  FieldValue,
  FormApi,
  FormErrors,
  FormProps,
  FormState,
  FormTouched,
  FormValidator,
  FormValues,
  InputProps,
} from './types';
import {hasChanges, hasErrors, isChangeEvent} from './utils';

type UseFormProps<V extends FormValues> = {
  initialValues: V;
  onChange?: (state: FormState<V>) => void;
  onSubmit: ((state: FormState<V>) => Promise<void>) | ((state: FormState<V>) => void);
  validator?: FormValidator<V>;
};
type UseFormRenderProps<V extends FormValues> = FormApi<V> & {
  field: <K extends keyof V>(props: FieldProps<V[K], K extends string ? K : string>) => JSX.Element;
  form: (props: FormProps<V>) => JSX.Element;
};

const DEFAULT_VALIDATOR: FormValidator<FormValues> = () => ({});

export const useForm = <V extends FormValues>({
  initialValues,
  onChange,
  onSubmit,
  validator = DEFAULT_VALIDATOR,
}: UseFormProps<V>): UseFormRenderProps<V> => {
  const [values, setValues] = useState<V>(initialValues);
  const [errors, setErrors] = useState<FormErrors<V>>({});
  const [touched, setTouched] = useState<FormTouched<V>>({});

  const formId = useId();
  const formState = useMemo<FormState<V>>(() => {
    const invalid = hasErrors(errors);

    return {
      values,
      errors,
      touched,
      hasErrors: invalid,
      hasChanges: hasChanges(touched),
    };
  }, [errors, touched, values]);

  // Actions
  const handleReset = useCallback((nextValues: V) => {
    setValues(nextValues);
    setErrors({});
    setTouched({});
  }, []);
  const handleSetError = useCallback(
    <K extends keyof V>(name: K, error: FieldError) =>
      setErrors((prevErrors) => ({...prevErrors, [name]: error})),
    [],
  );
  const handleSetTouched = useCallback(
    <K extends keyof V>(name: K) => setTouched((prevTouched) => ({...prevTouched, [name]: true})),
    [],
  );
  const handleSetValue = useCallback(
    <K extends keyof V>(name: K, value: V[K]) =>
      setValues((prevValues) => ({...prevValues, [name]: value})),
    [],
  );
  const handleSubmit = useCallback(
    async (event?: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event?.preventDefault();

      if (!formState.hasErrors) {
        return onSubmit(formState);
      }

      setTouched({
        ...touched,
        ...Object.fromEntries(Object.keys(errors).map((name) => [name, true])),
      });

      return undefined;
    },
    [errors, formState, onSubmit, touched],
  );

  const formApi = useMemo<FormApi<V>>(
    () => ({
      ...formState,
      id: formId,
      reset: handleReset,
      setError: handleSetError,
      setTouched: handleSetTouched,
      setValue: handleSetValue,
      submit: handleSubmit,
    }),
    [
      formId,
      formState,
      handleReset,
      handleSetError,
      handleSetTouched,
      handleSetValue,
      handleSubmit,
    ],
  );

  useEffect(() => {
    setErrors(validator(values));
  }, [validator, values]);

  useEffect(() => {
    onChange?.(formState);
  }, [formState, onChange]);

  return useMemo(
    () => ({
      ...formApi,
      field: Field,
      form: Form,
    }),
    [formApi],
  );
};

type UseFieldProps<K extends string> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormApi<any>;
  name: K;
};
type UseFieldRenderProps<V extends FieldValue, K extends string> = InputProps<V, K>;

export const useInput = <V extends FieldValue, K extends string>({
  form,
  name,
}: UseFieldProps<K>): UseFieldRenderProps<V, K> => {
  const handleBlur = useCallback(() => {
    form.setTouched(name);
  }, [form, name]);
  const handleChange = useCallback(
    (eventOrValue: ChangeEvent | V) => {
      const value = isChangeEvent(eventOrValue)
        ? (eventOrValue.target.value as unknown as V)
        : eventOrValue;

      form.setValue(name, value);
    },
    [form, name],
  );

  return useMemo<InputProps<V, K>>(
    () => ({
      name,
      error: form.errors[name],
      value: form.values[name],
      touched: Boolean(form.touched[name]),
      onBlur: handleBlur,
      onChange: handleChange,
    }),
    [form.errors, form.touched, form.values, handleBlur, handleChange, name],
  );
};
