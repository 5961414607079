import {isRecord} from 'utils/guards';

import {
  ChangeEvent,
  FormErrors,
  FormTouched,
  FormValidator,
  FormValidators,
  FormValues,
} from './types';

export const isChangeEvent = (value: unknown): value is ChangeEvent => {
  return isRecord(value) && isRecord(value.target) && 'type' in value.target;
};

export const hasErrors = <V extends FormValues>(errors: FormErrors<V>): boolean => {
  return Object.values(errors).some(Boolean);
};

export const hasChanges = <V extends FormValues>(touched: FormTouched<V>): boolean => {
  return Object.values(touched).some(Boolean);
};

export const createFormValidator =
  <V extends FormValues>(validators: FormValidators<V>): FormValidator<V> =>
  (values) => {
    return Object.entries(values).reduce<FormErrors<V>>((acc, [key, value]) => {
      const validator = validators[key];

      if (validator) {
        const error = validator(value as V[string]);

        if (error) {
          acc[key as keyof V] = error;
        }
      }

      return acc;
    }, {});
  };
