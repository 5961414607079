import {requestActionCreator} from 'store/utils/requestActions';
import {
  FeedbackLoadRequest,
  FeedbackLoadResult,
  FeedbackSendRequest,
  FeedbackSendResult,
} from 'types/Feedback';
import {FuncAction} from 'typesafe-actions';

import {load, send} from './actions';

type FeedbackLoadResponseBody = {
  payload: FeedbackLoadResult;
};

type FeedbackSendResponseBody = {
  payload: FeedbackSendResult;
};

export const loadFeedbackStep = ({
  context,
  id,
}: FeedbackLoadRequest): FuncAction<Promise<FeedbackLoadResult>> =>
  requestActionCreator(load, {context, id}, (store, client) =>
    client.api
      .post<FeedbackLoadResponseBody>(`/feedback/steps${id ? `/${id}` : ''}/get`, {
        body: {context},
      })
      .then(({body: {payload}}) => payload),
  );

export const sendFeedback = (data: FeedbackSendRequest): FuncAction<Promise<FeedbackSendResult>> =>
  requestActionCreator(send, data, (store, client) =>
    client.api
      .post<FeedbackSendResponseBody>('/feedback', {body: data})
      .then(({body: {payload}}) => payload),
  );
